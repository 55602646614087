<template>
  <!-- 新增挂牌 -->
  <div class="page-info-content">
    <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="135px" label-position="left" class="form-item-row" :disabled="formDisabled">
      <h3 class="page-subtitle-shade">
        基本信息
      </h3>
      <el-form-item label="商品类别" prop="tradeGoodsListing.goodsCategoryIdAggregate" :rules="rules.selectiveType">
        <el-cascader
          ref="goodsCategoryRef"
          v-model="formData.tradeGoodsListing.goodsCategoryIdAggregate"
          placeholder="请选择"
          :options="categoryOption"
          :props="categoryCascadeProps"
          clearable
        />
      </el-form-item>
      <el-form-item label="商品材质" prop="tradeGoodsListing.goodsMaterialName" :rules="rules.selectiveType">
        <el-select
          v-model="formData.tradeGoodsListing.goodsMaterialName"
          placeholder="请选择商品材质"
        >
          <el-option
            v-for="item in goodsMaterialOption"
            :key="item.materials"
            :label="item.materials"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="商品规格" prop="tradeGoodsListing.goodsSpecName" :rules="rules.selectiveType">
        <el-select
          v-model="formData.tradeGoodsListing.goodsSpecName"
          placeholder="请选择商品规格"
        >
          <el-option
            v-for="item in goodsSpecOption"
            :key="item.specs"
            :label="item.specs"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="件重（吨）" prop="tradeGoodsListing.pieceWeight">
        <el-input v-model="formData.tradeGoodsListing.pieceWeight" placeholder="请输入件重" />
      </el-form-item>
      <el-form-item label="商品名称" prop="tradeGoodsListing.goodsName" :rules="rules.name">
        <el-input v-model="formData.tradeGoodsListing.goodsName" placeholder="默认为品类/材质/规格的拼接" />
      </el-form-item>
      <el-form-item label="仓库名称" prop="tradeGoodsListing.warehouseName" :rules="[...rules.message, { min: 1, max: 15, message: '不超过15个字符', trigger: 'blur' }]">
        <el-input v-model="formData.tradeGoodsListing.warehouseName" :disabled="formItemDisabled" placeholder="请输入商品所在仓库名称" />
      </el-form-item>
      <el-form-item label="发货地区" prop="tradeGoodsListing.allAddress" :rules="rules.selectiveType">
        <el-cascader
          v-model="formData.tradeGoodsListing.allAddress"
          :disabled="formItemDisabled"
          :props="{ label: 'name', value: 'name', children: 'children' }"
          :options="allCityOption"
        />
      </el-form-item>
      <el-form-item label="详细地址" prop="tradeGoodsListing.placeDetailAddr" :rules="rules.name">
        <el-input v-model="formData.tradeGoodsListing.placeDetailAddr" :disabled="formItemDisabled" placeholder="请输入详细地址" />
      </el-form-item>
      <el-form-item label="钢厂/厂家" prop="tradeGoodsListing.manufactor" :rules="[{ min: 1, max: 15, message: '不超过15个字符', trigger: 'blur' }]">
        <el-input v-model="formData.tradeGoodsListing.manufactor" :disabled="formItemDisabled" placeholder="请输入钢厂/厂家" />
      </el-form-item>
      <el-form-item label="挂牌联系人" prop="tradeGoodsListing.listingPerson" :rules="[...rules.message, { min: 2, max: 20, message: '长度为2~20个字符', trigger: 'blur' }]">
        <el-input v-model="formData.tradeGoodsListing.listingPerson" placeholder="请输入挂牌联系人" />
      </el-form-item>
      <el-form-item label="联系手机号" prop="tradeGoodsListing.personPhone" :rules="rules.phone">
        <el-input v-model="formData.tradeGoodsListing.personPhone" placeholder="请输入联系手机号" />
      </el-form-item>
      <h3 class="page-subtitle-shade">
        价格信息
      </h3>
      <el-form-item label="可用库存（吨）" prop="tradeGoodsListing.availableInventoryWeight">
        <el-input v-model="formData.tradeGoodsListing.availableInventoryWeight" disabled placeholder="" />
      </el-form-item>
      <el-form-item label="挂牌数量（吨）" prop="tradeGoodsListing.listingWeight" :rules="rules.message">
        <el-input-number v-model="formData.tradeGoodsListing.listingWeight" :precision="3" :step="1" :min="0.001" :max="formData.tradeGoodsListing.availableInventoryWeight ? formData.tradeGoodsListing.availableInventoryWeight : Infinity" placeholder="请输入挂牌数量" />
      </el-form-item>
      <el-form-item label="最小起订量（吨）" prop="tradeGoodsListing.minOrderQuantity" :rules="rules.message">
        <el-input-number v-model="formData.tradeGoodsListing.minOrderQuantity" :precision="3" :step="1" :min="0.001" :max="formData.tradeGoodsListing.listingWeight" placeholder="请输入最小起订量" />
      </el-form-item>
      <div class="tooltip-item">
        <el-tooltip effect="dark" content="仅输入货款价，不含运费，运费在买家下单流程单独计算" placement="top-start">
          <i class="el-icon-warning" />
        </el-tooltip>
        <el-form-item label="不含税单价(元)" prop="tradeGoodsListing.unitPriceExcludingTax" :rules="rules.message">
          <el-input-number v-model="formData.tradeGoodsListing.unitPriceExcludingTax" :precision="2" :step="1" :min="0.01" placeholder="请输入不含税单价（元）" />
        </el-form-item>
      </div>
      <el-form-item label="税率" prop="tradeGoodsListing.taxRate" :rules="rules.selectiveType">
        <el-select
          v-model="formData.tradeGoodsListing.taxRate"
          placeholder="请选择税率"
        >
          <el-option
            v-for="item in $store.getters.getDictionaryItem('TAX_RATE')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictName"
          />
        </el-select>
      </el-form-item>
      <div class="tooltip-item">
        <el-tooltip effect="dark" content="含税单价=不含税单价*（1+税率）" placement="top-start">
          <i class="el-icon-warning" />
        </el-tooltip>
        <el-form-item label="含税单价（元）" prop="tradeGoodsListing.unitPriceIncludingTax">
          <el-input-number v-model="formData.tradeGoodsListing.unitPriceIncludingTax" :precision="2" :step="1" :min="0.01" placeholder="按照税率自动计算出含税单价" disabled />
        </el-form-item>
      </div>
      <div class="tooltip-item">
        <el-tooltip effect="dark" content="已选日期当日24:00自动下架" placement="top-start">
          <i class="el-icon-warning" />
        </el-tooltip>
        <el-form-item label="挂牌有效期" prop="tradeGoodsListing.listingValidDate" :rules="rules.selectiveType">
          <el-date-picker
            v-if="!isDateChecked"
            v-model="formData.tradeGoodsListing.listingValidDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :disabled-date="disabledDate"
          />
          <el-checkbox v-model="isDateChecked" label="长期有效" size="large" />
        </el-form-item>
      </div>
      <el-form-item label="计重方式" prop="tradeGoodsListing.weightStyle">
        <el-radio-group v-model="formData.tradeGoodsListing.weightStyle" class="ml-4">
          <el-radio label="00" size="large">
            过磅
          </el-radio>
          <el-radio label="01" size="large">
            理计
          </el-radio>
          <el-radio label="02" size="large">
            抄码(按挂牌价出售)
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <h3 class="page-subtitle-shade">
        支付信息
      </h3>
      <el-form-item label="支付方式">
        <div><span><b class="el-icon-success" /> 全款购买</span>&nbsp;&nbsp;<span><b class="el-icon-success" /> 保证金预付</span></div>
      </el-form-item>
      <el-form-item label="配送方式" prop="tradeGoodsListing.deliveryRuleData" :rules="rules.selectiveType">
        <el-checkbox-group v-model="formData.tradeGoodsListing.deliveryRuleData">
          <el-checkbox v-show="deliveryRuleList[0] === '00'" label="00" size="large">
            买家自提
          </el-checkbox>
          <el-checkbox v-show="deliveryRuleList[1] === '01'" label="01" size="large">
            商家配送
          </el-checkbox>
          <el-checkbox v-show="deliveryRuleList[2] === '02'" label="02" size="large">
            过户
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <h3 class="page-subtitle-shade">
        商品信息
      </h3>
      <h6>商品图片及详情说明</h6>
      <el-form-item label="商品主图" prop="tradeGoodsImgList" class="table-form-item image-upload" :rules="rules.uploadFile">
        <ImageUpload :upload-file-list.sync="formData.tradeGoodsImgList" :form-disabled="formDisabled" />
        <span v-if="formData.tradeGoodsImgList && formData.tradeGoodsImgList.length > 0" class="tips">默认主图</span>
      </el-form-item>
      <el-form-item label="详细说明" prop="" class="table-form-item ">
        <TinymceEditor ref="quillEditor" :read-only="formDisabled" :introduction-content.sync="formData.tradeGoodsListing.goodsDescription" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload2.vue'
import TinymceEditor from '@/components/TinymceEditor'
import { rules } from '@/utils/matchFormRegex'
import { goodsGetGoodsCategoryList, goodsGetGoodsSpecList, goodsGetGoodsMaterialList, tradeGoodsListingDetail, tradeDeliveryRuleQuery } from '@/api/shoppingMall'
import { getSelectAreaTree } from '@/api/login'

export default {
  components: { ImageUpload, TinymceEditor },
  data() {
    return {
      rules,
      buttonLoading: {
        loadingOne: false,
        loadingTwo: false
      },
      deliveryRuleList: [],
      formData: {
        tradeGoodsListing: {
          goodsSource: '01',
          goodsDescription: '',
          taxRate: '13%',
          deliveryRuleData: []
        }, // 现货挂牌
        tradeGoodsImgList: [] // 商品主图信息
      },
      formDisabled: false,
      formItemDisabled: false,
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName',
        children: 'children'
      },
      // 商品名称
      categoryOption: [],
      // 规格
      goodsSpecOption: [],
      // 材质
      goodsMaterialOption: [],
      allCityOption: [],
      disabledDate: (time) => {
        return time.getTime() < Date.now() - 86400000
      },
      isDateChecked: false
    }
  },
  mounted() {
    // 获取所有的城市
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
    if (this.$route.query.id) {
      this.getDateils()
      return
    }
    this.getTradeDeliveryRuleQuery('01')
  },
  methods: {
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.categoryOption = res.data
        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map((item) => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },

    // 材质跟型号的搜索
    associativeSearch() {
      if (!this.formData.tradeGoodsListing.goodsTwoCategoryId) return
      goodsGetGoodsMaterialList(this.formData.tradeGoodsListing.goodsTwoCategoryId, res => {
        this.goodsMaterialOption = [...res.data]
      })
      goodsGetGoodsSpecList(this.formData.tradeGoodsListing.goodsTwoCategoryId, res => {
        this.goodsSpecOption = [...res.data]
      })
    },
    // 查询配送规则
    getTradeDeliveryRuleQuery(deliveryRule) {
      this.deliveryRuleList = []
      tradeDeliveryRuleQuery(deliveryRule, res => {
        if (res.data[0].buyerPickUp === 1) {
          this.deliveryRuleList[0] = '00'
        }
        if (res.data[0].merchantDistribution === 1) {
          this.deliveryRuleList[1] = '01'
        }
        if (res.data[0].transfer === 1) {
          this.deliveryRuleList[2] = '02'
        }
        this.$forceUpdate()
      })
    },
    // 查询详情
    async getDateils() {
      const res = await tradeGoodsListingDetail(this.$route.query.id)
      this.getTradeDeliveryRuleQuery(res.data.tradeGoodsListing.goodsSource)
      this.formData.tradeGoodsListing = { ...res.data.tradeGoodsListing }
      this.formData.tradeGoodsImgList = [...res.data.tradeGoodsImgList]
      this.formData.tradeGoodsListing.goodsCategoryIdAggregate = [this.formData.tradeGoodsListing.goodsTopCategoryId, this.formData.tradeGoodsListing.goodsOneCategoryId, this.formData.tradeGoodsListing.goodsTwoCategoryId]
      this.formData.tradeGoodsListing.allAddress = [this.formData.tradeGoodsListing.placeProvince, this.formData.tradeGoodsListing.placeCity, this.formData.tradeGoodsListing.placeTown]
      this.formData.tradeGoodsListing.deliveryRuleData = ['', '', '']
      if (this.formData.tradeGoodsListing.listingValidDate === '-1') {
        this.isDateChecked = true
      }
      // 买家自提：1.支持 0.不支持
      if (this.formData.tradeGoodsListing.buyerPickUp === '1') {
        this.formData.tradeGoodsListing.deliveryRuleData[0] = '00'
      }
      // 商家配送：1.支持 0.不支持
      if (this.formData.tradeGoodsListing.merchantDistribution === '1') {
        this.formData.tradeGoodsListing.deliveryRuleData[1] = '01'
      }
      // 过户：1.支持 0.不支持
      if (this.formData.tradeGoodsListing.transfer === '1') {
        this.formData.tradeGoodsListing.deliveryRuleData[2] = '02'
      }
      // if (this.$route.query.id && this.formData.tradeGoodsListing.goodsStatus === '01' || this.formData.tradeGoodsListing.goodsStatus === '02') {
      //   this.formDisabled = true
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.table-form-item {
  width: 100%;
  /deep/ .el-form-item__content {
   width: 90%;
  }
}
.operation{
  padding-left: 135px;
  :deep(.el-button){
    width: 112px;
  }
}
.select-inventory {
  cursor: pointer;
  margin-right: 80px;
}
.image-upload {
  /deep/ .el-form-item__content {
    position: relative;
    .tips {
      width: 146px;
      position: absolute;
      left: 1px;
      top: 107px;
      text-align: center;
      background-color: #606266;
      color: #fff;
      border-radius: 0 0 5px 5px;
    }
  }
}
.tooltip-item {
  width: 45%;
  position: relative;
  .el-form-item{
    width: 100%;
  }
  /deep/ .el-tooltip {
    position: absolute;
    left: 110px;
    top: 9px;
    color: #999;
  }
}
</style>
